import { IMeasurementsPolygonScan } from './IMeasurementsScan';
import { Points } from './PointCoordinates';

export type { TEyeSizeTypes } from '_api/AltrisRESTClient/types';

import { OverlayLine } from 'pages/Examination/Examination.types';

export type IClassesForCurrentPlan = {
  [key: string]: {
    class_name: string;
    low_confidence: boolean;
    list_position: number;
  };
};

export type IDistanceType = 'axial';

/***
 use this enum to get color by severity or severity by color

 Examples:
 EColorToSeverity[colorName as string] = severity
 EColorToSeverity[severity as number] as TPriorityColor = colorName
**/
export enum EColorToSeverity {
  green,
  yellow,
  red,
}

export type TPriorityColor = keyof typeof EColorToSeverity;

export type TPriorityColorFilter = TPriorityColor | 'all';

export type TConfidenceStr = 'sure' | 'unsure';

export type TSeries = 'high-low' | 'low-high' | 'in-series';

export type ISeriesTypes = {
  HIGH_LOW: string;
  LOW_HIGH: string;
  IN_SERIES: string;
};

export type IMatrixMap = { mean: number }[][];
export type IMatrixMapDifference = { mean: number; diff: 'plus' | 'minus' }[][];

export type IMatrixNumber = number[][];

export type ScanLayersStatus = 'failed' | 'processing' | 'finished' | null;

export interface ChangedOpacityPolygons {
  className: string;
  opacity: string;
}

export interface PathologySizeTypes {
  mm: string;
  nl: string;
}

export interface IExamScanRequest {
  exam_id: string;
  scan_id: string;
}

export interface IUpdateMeasData {
  exam_id: string;
  scan_id: string;
  pathologies: IMeasurementsPolygonScan[];
}

export interface IUpdateMeasManualData {
  exam_id: string;
  scan_id: string;
  lines: OverlayLine[];
}

export type IMeasDistanceRequest = {
  exam_id: string;
  points: Points;
};

export type ExamEyeDirection = 'Vertical' | 'Horizontal';

export interface IndexesTypes {
  prid: number;
  active: number;
  max: number;
}
