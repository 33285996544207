import { t } from 'i18next';
import { delay, fork, put, select, takeLeading } from 'redux-saga/effects';

import { EXAMINATION, PATIENT } from 'api/endpoints';
import { getExaminationStatus } from 'api/examination';
import { getSinglePatient } from 'api/patient';
import { getExaminationStatusError } from 'redux/actions/examination';
import { getPatientSuccess } from 'redux/actions/patient';
import { ExamActionTypes } from 'redux/constants/examination';
import { patientsSelector } from 'redux/selectors/patients';
import { TExamBase } from 'types/examination';
import { IPatient } from 'types/patients';
import { revalidatePaths } from 'utils/apiCache';
import { EXAMINATION_STATUS } from 'utils/constants';
import notify from 'utils/toast';

function* getExaminationStatusWatcher() {
  yield takeLeading(
    ExamActionTypes.GET_EXAMINATION_STATUS_REQUEST,
    getExaminationStatusWorker
  );
}

interface IExaminationStatusProps {
  type: ExamActionTypes;
  payload: TExamBase;
}

function* getExaminationStatusWorker({ payload }: IExaminationStatusProps) {
  try {
    let examinationStatus;

    const { data: status } = yield getExaminationStatus({
      exam_id: payload.id,
    });
    examinationStatus = status.data.status;

    /**
     * Every 1.5 seconds we are checking the status of the examination
     * until it is not finished or failed
     */
    while (examinationStatus === EXAMINATION_STATUS.PROCESSING) {
      yield delay(1500);

      const { data: status } = yield getExaminationStatus({
        exam_id: payload.id,
      });
      examinationStatus = status.data.status;
    }

    if (examinationStatus == EXAMINATION_STATUS.FAILED) {
      yield notify('error', t('notifications.examination_failed'));
    }

    if (examinationStatus == EXAMINATION_STATUS.FINISHED) {
      yield notify('success', t('notifications.examination_available'));
    }

    const { data: patientsOldData } = yield select(patientsSelector);

    const patientId = patientsOldData.patients.find((item: IPatient) => {
      return item.examinations?.some(({ id }) => id === payload.id);
    });

    yield revalidatePaths([
      EXAMINATION.EXAMINATION_ID(payload.id),
      EXAMINATION.GET_PROGRESSION_STATE(payload.id),
      PATIENT.GET_ALL_PATIENTS,
      PATIENT.PATIENT_ID(patientId),
    ]);

    /**
     * Getting the updated data of one patient to update the list, this is needed
     * when we need to update the level of concern icon on the patient
     */
    const { data: singlePatientData } = yield getSinglePatient(
      payload.patient_id
    );

    if (singlePatientData.status === 'Error') {
      throw new Error(singlePatientData.message);
    }

    const { data: patientsData } = yield select(patientsSelector);

    /**
     * Updating the patient list with the updated date of the
     * single patient which was updated by examination without
     * requesting the whole list of patients
     */
    yield put(
      getPatientSuccess({
        ...patientsData,
        patients: patientsData.patients.map((item: IPatient) => {
          return item.id === payload.patient_id ? singlePatientData.data : item;
        }),
      })
    );
  } catch (error: any) {
    console.error(error);
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(getExaminationStatusError(errorMessage));
    yield notify('error', errorMessage);
  }
}

export default function* getExaminationStatusSaga() {
  yield fork(getExaminationStatusWatcher);
}
