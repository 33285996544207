import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

/**
 * Gets a user backup.
 * @return {Promise<TResponse>} The user backup.
 * @throws Will throw an error if the request fails.
 */
export async function getUserBackup(this: BaseRESTClient) {
  try {
    return await this.client.get<TResponse>('/examination/download');
  } catch (error) {
    console.error(error);
    throw error;
  }
}
