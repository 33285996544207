import {
  lazy,
  memo,
  Suspense,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import cn from 'classnames';
import { useLocation } from 'react-router-dom';

import { ReactComponent as ArrowLeft } from 'assets/icons/angle-left.svg';
import FormWrap from 'components/FormWrap';
import MetaInfo from 'components/MetaInfo';
import Spinner from 'components/Spinner';
import useDynamicViewportSize from 'hooks/useDynamicViewportSize';
import { MOBILE_RESOLUTION, ROUTES } from 'utils/constants';

import styles from './PublicLayout.module.scss';

const OnboardingMobile = lazy(
  () => import('components/Welcome/OnboardingMobile')
);
const OcuPlanMobile = lazy(() => import('components/Welcome/OcuPlanMobile'));

const Welcome = lazy(() => import('components/Welcome'));
const OcuPlanText = lazy(() => import('components/Law/OcuPlan/OcuPlan'));

const PublicLayout = memo(() => {
  const { vw } = useDynamicViewportSize();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const [isShowOnMobile, setIsShowOnMobile] = useState(false);

  // useLayoutEffect(() => {
  //   if (vw <= MOBILE_RESOLUTION) {
  //     setIsShowOnMobile(true);
  //   }
  // }, [vw]);

  useEffect(() => {
    if (pathname.indexOf(ROUTES.AUTH.FORGOT) === 0) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [pathname]);

  return (
    <div
      className={cn(styles.main, {
        [styles.close]: !isOpen,
      })}
    >
      <MetaInfo />
      <div className={styles['form-wrapper']}>
        <FormWrap />
      </div>

      {!(vw <= MOBILE_RESOLUTION) && (
        <div
          className={cn(styles['welcome-wrapper'], {
            [styles.close]: !isOpen,
          })}
        >
          <Suspense fallback={<Spinner className={styles.spinner} />}>
            <div
              className={cn(styles.opener, {
                [styles.close]: isOpen,
              })}
              onClick={() => setIsOpen((state) => !state)}
            >
              <ArrowLeft />
            </div>
            {/**
             * Change side component depending on the page path
             */}
            {pathname.indexOf(ROUTES.LAW.FILL_FORM) === 0 ? (
              <OcuPlanText className={styles['welcome-block']} />
            ) : (
              <Welcome className={styles['welcome-block']} />
            )}
          </Suspense>
        </div>
      )}

      {isShowOnMobile && pathname.indexOf(ROUTES.AUTH.REGISTER) !== 0 && (
        <Suspense fallback={''}>
          {/**
           * OnboardingMobile component is rendered only on mobile devices
           * and only on pages that are not ROUTES.LAW.FILL_FORM
           */}
          {pathname.indexOf(ROUTES.LAW.FILL_FORM) === 0 ? (
            <OcuPlanMobile
              className={cn({
                [styles.hide]: !isShowOnMobile,
              })}
              setIsShowOnMobile={setIsShowOnMobile}
            />
          ) : (
            <OnboardingMobile
              className={cn({
                [styles.hide]: !isShowOnMobile,
              })}
              setIsShowOnMobile={setIsShowOnMobile}
            />
          )}
        </Suspense>
      )}
    </div>
  );
});

PublicLayout.displayName = 'PublicLayout';

export default PublicLayout;
