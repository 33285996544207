import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getGlaucomaData } from 'api/examination';
import {
  IGlaucomaRequestAction,
  getGlaucomaDataError,
  getGlaucomaDataSuccess,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { IWorkerProps } from 'redux/types/examination';
import { TGlaucomaExam } from 'types/glaucoma/TGlaucomaExam';
import notify from 'utils/toast';

function* glaucomaDataWatcher() {
  yield takeEvery<string, any>(
    ExamActionTypes.GET_GLAUCOMA_DATA_REQUEST,
    glaucomaWorker
  );
}

interface IGlaucoma extends IWorkerProps {
  type: ExamActionTypes;
  action?: IGlaucomaRequestAction;
  payload: Record<'exam_id', string>;
  abortSignal?: AbortSignal;
}

function* glaucomaWorker({ payload, action, abortSignal }: IGlaucoma) {
  action?.default?.();
  try {
    const { exam_id } = payload;

    const {
      data: { data },
    } = yield getGlaucomaData(exam_id, abortSignal);

    yield put(getGlaucomaDataSuccess(data as TGlaucomaExam | null));
    // if (data.status === 'Error') {
    //   throw new Error(data.message);
    // }
    action?.success?.(data as TGlaucomaExam | null);
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    if (error !== 'canceled') {
      yield notify('error', errorMessage);
    }

    action?.error?.();
    yield put(getGlaucomaDataError());
  }
}

export default function* glaucomaDataSaga() {
  yield fork(glaucomaDataWatcher);
}
