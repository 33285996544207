import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

/**
 * Confirm a purchase
 * @return {Promise<TResponse>} The server's response.
 * @throws Will throw an error if the request fails.
 * @param payload
 */
export async function confirmPurchase(this: BaseRESTClient, payload: FormData) {
  try {
    return await this.client.post<TResponse>('/purchase-confirm', payload);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
