import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

export async function checkUserEmail(this: BaseRESTClient, paylaod: FormData) {
  try {
    return await this.client.post<TResponse>('/user/check-email', paylaod);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
