import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

import { ISettingsData, ISettingsSaveData } from 'types/settings';

export interface TSettingsSaveDataPayload {
  [key: string]: ISettingsData['status'];
}
/**
 * Saves the settings data.
 * @return {Promise<TResponse>} The response from saving the settings data.
 * @throws Will throw an error if the request fails.
 * @param payload
 */
export async function saveSettings(
  this: BaseRESTClient,
  payload: TSettingsSaveDataPayload
) {
  try {
    return await this.client.put<TResponse>('/user/platform-settings', {
      platform_settings: payload,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}
