import BaseRESTClient from '../../../BaseRESTClient';
import { TResponseError } from '../../types';

/**
 * @function updateInvitedUserData
 * @alias updateInvitedUserData
 * @see https://github.com/axios/axios
 */

type TUpdateUserDataPayload = {
  name: string;
  email: string;
};

export async function updateInvitedUserData(
  this: BaseRESTClient,
  id: number,
  payload: TUpdateUserDataPayload
) {
  try {
    return await this.client.put(`/auth/invited-user-data/${id}`, payload);
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
