import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TEyeSizeTypes, TResponse, TResponseError } from '../../types';

/**
 * @function updateExamination
 * @alias updateExamination
 * @see https://github.com/axios/axios
 */

interface TUpdateExamDataPayload {
  examination_date?: number;
  eye?: 'OD' | 'OS';
  note?: string;
  pid?: string;
  direction?: 'Vertical' | 'Horizontal';
  width?: TEyeSizeTypes;
}

interface TUpdateExamDataParams {
  id: string;
}

export async function updateExamination(
  this: BaseRESTClient,
  params: TUpdateExamDataParams,
  payload: TUpdateExamDataPayload,
  cancelToken?: CancelToken
) {
  try {
    return await this.client.put<TResponse>(
      `/examinations/${params.id}`,
      payload,
      {
        cancelToken,
      }
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
