import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

interface TUpdatePatientParams {
  id: string;
}
interface TUpdatePatientPayload {
  birth_date: number;
  description?: string;
  gender: string;
  name: string;
  pid: string;
  surname: string;
}

export async function updatePatient(
  this: BaseRESTClient,
  params: TUpdatePatientParams,
  payload: TUpdatePatientPayload
) {
  try {
    const response = await this.client.put<TResponse>(
      `/patients/${params.id}`,
      payload
    );
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}
