import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponseError } from '../../types';

interface TGetProgressCommonHeatmapParams {
  examination_ids: string[];
  pathology_id: string;
}

/**
 * @function getProgressCommonHeatmapData
 * @alias getProgressCommonHeatmapData
 * @see https://github.com/axios/axios
 */

export async function getProgressCommonHeatmapData(
  this: BaseRESTClient,
  params: TGetProgressCommonHeatmapParams,
  cancelToken?: CancelToken
) {
  try {
    return await this.client.get(`/examinations/progress/common/heatmap`, {
      params,
      cancelToken,
    });
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
