import { AxiosRequestConfig } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponseError } from '../../types';

import { TGlaucomaExam } from 'types/glaucoma/TGlaucomaExam';

/**
 * @function getGlaucomaData
 * @alias getGlaucomaData
 * @see https://github.com/axios/axios
 */

export interface TGetGlaucomaDataParams {
  examId: string;
}

export type TGetGlaucomaDataError = TResponseError;
export type TGetGlaucomaDataSuccess = {
  data: TGlaucomaExam;
  opposite_eye: TGlaucomaExam | null;
};

export async function getGlaucomaData(
  this: BaseRESTClient,
  params: TGetGlaucomaDataParams,
  config?: AxiosRequestConfig
): Promise<TGetGlaucomaDataSuccess> {
  try {
    const { data } = await this.client.get<TGetGlaucomaDataSuccess>(
      `/examination/glaucoma/${params.examId}`,
      {
        ...config,
      }
    );
    return data;
  } catch (error: any | TGetGlaucomaDataError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
