import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse, TResponseError } from '../../types';

import { PointCoordinates } from 'types/examination';

export type TGetMeasPointsDistanceParams = {
  exam_id: string;
  points: {
    p1: PointCoordinates;
    p2: PointCoordinates;
  };
};
/**
 * @function getMeasPointsDistance
 * @alias getMeasPointsDistance
 * @see https://github.com/axios/axios
 */

export async function getMeasPointsDistance(
  this: BaseRESTClient,
  payload: TGetMeasPointsDistanceParams,
  cancelToken?: CancelToken
) {
  try {
    return await this.client.get<TResponse>(
      `/examinations/${payload.exam_id}/measurements/distance/${payload.points}`,
      { cancelToken }
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
