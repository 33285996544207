import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

interface TReinviteTeamMemberParams {
  id: number;
}
/**
 * Reinvites a team member.
 * @return {Promise<TResponse>} The result of reinvitation.
 * @throws Will throw an error if the request fails.
 * @param params
 */
export async function reinviteTeamMember(
  this: BaseRESTClient,
  params: TReinviteTeamMemberParams
) {
  try {
    return await this.client.put<TResponse>(
      `/team-member/reinvite/${params.id}`
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
}
