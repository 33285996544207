import BaseRESTClient from '../../../BaseRESTClient';
import { TResponseError } from '../../types';

/**
 * @function sendOcuPlanForm
 * @alias sendOcuPlanForm
 * @see https://github.com/axios/axios
 */

type TOcuPlanFormPayload = {
  consent: boolean;
  date: string;
};

export async function sendOcuPlanForm(
  this: BaseRESTClient,
  payload: TOcuPlanFormPayload
) {
  try {
    return await this.client.post(`/consent-form-record`, payload);
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
