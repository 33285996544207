import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

interface TTeamMemberInvitePayload {
  name: string;
  surname: string;
  email: string;
}

/**
 * Invites a team member.
 * @return {Promise<TResponse>} The result of the invitation.
 * @throws Will throw an error if the request fails.
 * @param payload
 */
export async function inviteTeamMember(
  this: BaseRESTClient,
  payload: TTeamMemberInvitePayload
) {
  try {
    return await this.client.post<TResponse>('/team-member/invite', payload);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
