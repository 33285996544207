import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse, TResponseError } from '../../types';

/**
 * @function getPaymentMethod
 * @alias getPaymentMethod
 * @see https://github.com/axios/axios
 */

interface TGetPaymentMethodData {
  data: any;
}

export async function getPaymentMethod(this: BaseRESTClient) {
  try {
    return await this.client.get<TResponse<TGetPaymentMethodData>>(
      `/stripe/get-payment-methods`
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
