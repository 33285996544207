import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';

import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { RoutePath } from './router/registerUserRouter/registerUserRouter';

import {
  LayoutWithAside,
  PrivateLayout,
  PublicLayout,
  SimpleLayout,
} from 'components/Layout';
import Spinner from 'components/Spinner';
import { useIsDemo } from 'hooks/useIsDemo';
import Dashboard from 'pages/Dashboard';
import DemoUserRegistration from 'pages/DemoUserRegistration';
import ForgotPassword from 'pages/ForgotPassword';
import OcuPlan from 'pages/Law/OcuPlan';
import RegisterUser from 'pages/RegisterUser';
import ResetPassword from 'pages/ResetPassword';
import SignIn from 'pages/SignIn';
import { userAuthenticatedSelector } from 'redux/selectors/userAuthenticated';
import { profileRoleSelector } from 'redux/selectors/userProfile';
import { onBoardingRouteConfig, profileRouterConfig } from 'router';
import { generateRoutes } from 'router/helpers';
import { DEMO_ACCOUNT_GOOGLE_ANALYTICS, ROUTES } from 'utils/constants';
import { ga } from 'utils/helpers';
import { onboardingLinks, profileLinks } from 'utils/linksData';

const DataProtection = lazy(
  () => import('pages/Law/DateProtection/DateProtection')
);
const Examination = lazy(() => import('pages/Examination'));
const PrivacyPolicy = lazy(() => import('pages/Law/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('pages/Law/TermsAndConditions'));
const CommonCase = lazy(() => import('pages/Subscription/CommonCase'));

const App = () => {
  const { authenticated: isAuthenticated } = useSelector(
    userAuthenticatedSelector
  );
  const userRole = useSelector(profileRoleSelector);
  const location = useLocation();

  const isDemo = useIsDemo();

  const editProfileLinks = useMemo(() => profileLinks(), []);

  useEffect(() => {
    // Google Analytics will work only for prod domain.
    if (
      window.location.hostname === process.env.REACT_APP_APP_HOST &&
      process.env.REACT_APP_GOOGLE_ANALYTIC
    ) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTIC);
    } else if (isDemo && process.env.REACT_APP_DEMO_ANALYTIC) {
      ReactGA.initialize(process.env.REACT_APP_DEMO_ANALYTIC);

      ga(DEMO_ACCOUNT_GOOGLE_ANALYTICS.OPEN_PAGE_DEMO_ACCOUNT);
    }

    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }, [location]);

  if (isAuthenticated) {
    return (
      <Suspense
        fallback={
          <PrivateLayout>
            <Spinner />
          </PrivateLayout>
        }
      >
        <Routes>
          <Route path={ROUTES.DASHBOARD.MAIN} element={<PrivateLayout />}>
            <Route index element={<Dashboard />} />
            <Route path={ROUTES.EXAMINATION.MAIN} element={<Examination />} />

            {/* Routes for Profile */}
            <Route
              path={ROUTES.PROFILE.MAIN}
              element={<Navigate to={editProfileLinks[0].link} replace />}
            />

            <Route
              path={ROUTES.PROFILE.MAIN}
              element={<LayoutWithAside links={editProfileLinks} />}
            >
              {userRole.model &&
                generateRoutes(profileRouterConfig(userRole.model))}
              <Route
                path='*'
                element={<Navigate to={editProfileLinks[0].link} replace />}
              />
            </Route>

            {/* Routes for Onboarding */}
            <Route
              path={ROUTES.ONBOARDING.MAIN}
              element={<LayoutWithAside links={onboardingLinks()} />}
            >
              {generateRoutes(onBoardingRouteConfig)}
            </Route>

            <Route path={ROUTES.SERVICES.MAIN} element={<CommonCase />} />
          </Route>
          <Route path={ROUTES.LAW.PRIVACY} element={<PrivacyPolicy />} />
          <Route
            path={ROUTES.LAW.TERMS_AND_CONDITIONS}
            element={<TermsAndConditions />}
          />
          <Route
            path={ROUTES.LAW.DATA_PROTECTION}
            element={<DataProtection />}
          />
          <Route path='*' element={<Navigate to={ROUTES.DASHBOARD.MAIN} />} />
        </Routes>
      </Suspense>
    );
  }

  if (isDemo) {
    return (
      <Routes>
        <Route path={ROUTES.AUTH.LOGIN} element={<DemoUserRegistration />} />;
      </Routes>
    );
  }

  return (
    <Suspense fallback={<PublicLayout />}>
      <Routes>
        <Route path={ROUTES.AUTH.LOGIN} element={<PublicLayout />}>
          <Route path={RoutePath.SIGN_IN} element={<SignIn />} />
          <Route path={RoutePath.REGISTER_USER} element={<RegisterUser />} />
          <Route
            path={RoutePath.FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />
          <Route path={RoutePath.RESET_PASSWORD} element={<ResetPassword />} />
          <Route path={RoutePath.FILL_FORM} element={<OcuPlan />} />
        </Route>
        <Route
          path={ROUTES.LAW.PRIVACY}
          element={
            <Suspense
              fallback={
                <SimpleLayout>
                  <Spinner />
                </SimpleLayout>
              }
            >
              <PrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.LAW.TERMS_AND_CONDITIONS}
          element={
            <Suspense
              fallback={
                <SimpleLayout>
                  <Spinner />
                </SimpleLayout>
              }
            >
              <TermsAndConditions />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.LAW.DATA_PROTECTION}
          element={
            <Suspense
              fallback={
                <SimpleLayout>
                  <Spinner />
                </SimpleLayout>
              }
            >
              <DataProtection />
            </Suspense>
          }
        />
        <Route path='*' element={<Navigate to={ROUTES.AUTH.LOGIN} />} />
      </Routes>
    </Suspense>
  );
};

export default App;
