import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse, TResponseError } from '../../types';

/**
 * @function logout
 * @alias logout
 * @see https://github.com/axios/axios
 */
export async function logout(this: BaseRESTClient) {
  try {
    return await this.client.post<TResponse<'Token Revoked'>>(`/auth/logout`);
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
