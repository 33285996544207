import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponseError } from '../../types';

/**
 * @function getThicknessMap
 * @alias getThicknessMap
 * @see https://github.com/axios/axios
 */

interface TGetThicknessMapParams {
  exam_id: string;
  layers_slug?: string;
}
export async function getThicknessMap(
  this: BaseRESTClient,
  params: TGetThicknessMapParams,
  cancelToken?: CancelToken
) {
  try {
    return await this.client.get(
      `/examinations/${params.exam_id}/thickness-map/${params.layers_slug}`,
      {
        cancelToken,
      }
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
