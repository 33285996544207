import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse, TResponseError } from '../../types';

interface TSetExamFavoriteScanParams {
  exam_id: string;
  scan_id: string;
}

/**
 * @function setExamFavoriteScan
 * @alias setExamFavoriteScan
 * @see https://github.com/axios/axios
 */

export async function setExamFavoriteScan(
  this: BaseRESTClient,
  params: TSetExamFavoriteScanParams,
  cancelToken?: CancelToken
) {
  try {
    return await this.client.put<TResponse>(
      `/examinations/${params.exam_id}/starred`,
      { params: { scan_id: params.scan_id } },
      { cancelToken }
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
