import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

/**
 * Deletes a payment method.
 * @param {any} data - The data needed to delete the payment method.
 * @return {Promise<TResponse>} The response from deleting the payment method.
 * @throws Will throw an error if the request fails.
 */
export async function deletePaymentMethod(this: BaseRESTClient, data: any) {
  try {
    return await this.client.delete('/user/payment-method', data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
