import BaseRESTClient from '../../../BaseRESTClient';
import { TCountry, TResponse, TResponseError } from '../../types';

/**
 * @function getCountries
 * @alias getCountries
 * @see https://github.com/axios/axios
 */

export async function getCountries(this: BaseRESTClient) {
  try {
    return await this.client.get<TResponse<TCountry[]>>(`/location/countries`, {
      // cache: 'force-cache',
    });
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
