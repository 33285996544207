import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse, TResponseError } from '../../types';

/**
 * @function getMainETDRS
 * @alias getMainETDRS
 * @see https://github.com/axios/axios
 */

interface TGetMainETDRSParams {
  id: string;
}

export async function getMainETDRS(
  this: BaseRESTClient,
  params: TGetMainETDRSParams,
  cancelToken?: CancelToken
) {
  try {
    return await this.client.get<TResponse>(
      `/examinations/${params.id}/etdrs/main`,
      {
        cancelToken,
      }
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
