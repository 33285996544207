import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

/**
 * @function deleteMeasurementsScan
 * @alias deleteMeasurementsScan
 * @see https://github.com/axios/axios
 */

export interface TDeleteMeasurementsScanParams {
  exam_id: string;
  scan_id: string;
}

export async function deleteMeasurementsScan(
  this: BaseRESTClient,
  params: TDeleteMeasurementsScanParams,
  cancelToken?: CancelToken
) {
  try {
    return await this.client.delete<TResponse>(
      `/examinations/${params.exam_id}/scans/${params.scan_id}/measurements`,
      {
        cancelToken,
      }
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
}
