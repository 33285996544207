import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

// Represents the payload for the 'demoSignUp' function.
export type TDemoSignUpPayload = {
  email: string;
  name: string;
  profession_id: number;
  country_id: number;
  oct_producer_id: string;
  is_decision_maker: number;
  promo_code: string;
};

/**
 * This function registers a user through the demo endpoint.
 * @param payload - contains the data needed for user registration.
 * @returns The server response as a promise.
 * @throws Will throw an error if the registration fails.
 */
export async function demoSignUp(
  this: BaseRESTClient,
  payload: TDemoSignUpPayload
) {
  try {
    return await this.client.post<TResponse>(
      '/demo/complete-sign-up/',
      payload
    );
  } catch (err) {
    console.error(err);
    throw err;
  }
}
