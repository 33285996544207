import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponseError } from '../../types';

/**
 * @function checkEmailForgotPassword
 * @alias checkEmailForgotPassword
 * @see https://github.com/axios/axios
 */

type TResetPasswordPayload = {
  email: string;
};

export async function checkEmailForgotPassword(
  this: BaseRESTClient,
  payload: TResetPasswordPayload,
  cancelToken: CancelToken
) {
  try {
    return await this.client.post(`/auth/reset-password`, payload, {
      cancelToken,
    });
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
