import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse, TResponseError } from '../../types';

/**
 * @function updateProgressionState
 * @alias updateProgressionState
 * @see https://github.com/axios/axios
 */
interface UpdateProgressionStatePayload {
  examination_ids: string[];
}

export async function updateProgressionState(
  this: BaseRESTClient,
  payload: UpdateProgressionStatePayload,
  cancelToken?: CancelToken
) {
  try {
    return await this.client.put<TResponse>(
      `/examinations/progression/state`,
      payload,
      {
        cancelToken,
      }
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
