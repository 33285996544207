import axios, {
  AxiosError,
  AxiosResponse,
  CancelTokenSource,
  InternalAxiosRequestConfig,
} from 'axios';
import AuthManager from 'local-auth-manager';

import BaseRESTClient from '../BaseRESTClient';

import { bindAllApi } from './api';
import { TAltrisJWT } from './types';

/**
 * @class AltrisRESTClient
 * @category API
 * @alias BaseRESTClient
 * @inherits
 * @see https://github.com/axios/axios
 * @constructor
 * @classdesc
 */

interface TInitialConstructorProps {
  BASE_URL: string;
  key: string;
  version?: string;
  redirectPath?: string;
  defaultLanguage?: string;
  languageStorageKey?: string;
}

export default class AltrisRESTClient extends BaseRESTClient {
  public localAuthManager: AuthManager<TAltrisJWT>;
  private _language: string;
  private readonly languageStorageKey: string;
  private redirectPath;
  private readonly key;

  constructor({
    BASE_URL,
    key,
    redirectPath = '/',
    defaultLanguage = 'en',
    languageStorageKey = 'language',
  }: TInitialConstructorProps) {
    super(`${BASE_URL}`);
    this.key = key;

    this._language = defaultLanguage; // language variable for request
    this.languageStorageKey = languageStorageKey;

    this.redirectPath = redirectPath; // redirect to path on exception or token expired

    // Simple class object created for session control inside client (browser)
    this.localAuthManager = new AuthManager<TAltrisJWT>({
      storageType: 'localStorage',
      tokenKey: `token`, // key in storage
      parse: false,
      // EXAMPLE: altrisRESTClient.localAuthManager.tokenValidator(newToken)
      tokenValidator: (token: unknown): token is TAltrisJWT =>
        typeof token === 'string',
    });

    // axios config
    this.client.defaults.headers['Content-Type'] = 'application/json';
    this.client.interceptors.response.use(this.onResponse);
    this.client.interceptors.request.use(
      this.onRequest,
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );
  }

  private onResponse = (response: AxiosResponse) => {
    /*
     additional error checking when the status is 200
     but there is an error and Error is received in the response body
    */
    if (response.data.status === 'Error') throw new Error(response.data);
    return response;
  };

  private onRequest = (
    request: InternalAxiosRequestConfig<any>
  ): InternalAxiosRequestConfig<any> => {
    if (this.localAuthManager.isValidToken(this.localAuthManager.token))
      request.headers[
        'Authorization'
      ] = `Bearer ${this.localAuthManager.token}`;
    request.headers['Language'] = this._language;
    return request;
  };

  // create Cancel token for axios cancel request logic
  public readonly createCancelToken = (): CancelTokenSource =>
    axios.CancelToken.source();

  set language(value: string) {
    this._language = value;
  }

  get language(): string {
    return this._language;
  }

  public readonly utils = {
    createCancelToken: this.createCancelToken,
  };

  public readonly api = bindAllApi(this);

  /*
   context binding to imported endopints through a loop
   This need for optimization and better scalability of endpoints
  */
  /*  private bindEndpointsGroup<
    T extends { [key: string]: (...args: any[]) => any }
  >(
    endpointGroup: T
  ): {
    [K in keyof T]: T[K] extends (...args: infer A) => infer R
      ? (...args: A) => R
      : never;
  } {
    return Object.entries(endpointGroup).reduce(
      (group, [key, endpoint]) => ({
        ...group,
        [key]: endpoint.bind(this),
      }),
      {} as any
    );
  }*/
}
