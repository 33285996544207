import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse, TResponseError } from '../../types';

/**
 * @function getBothEyesOppositeEye
 * @alias getBothEyesOppositeEye
 * @see https://github.com/axios/axios
 */

export async function getBothEyesOppositeEye(
  this: BaseRESTClient,
  exam_id: string,
  cancelToken?: CancelToken
) {
  try {
    return await this.client.get<TResponse>(
      `/examinations/${exam_id}/both-eyes/opposite-eye`,
      { cancelToken }
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
