import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse, TResponseError } from '../../types';

/**
 * @function getExamLayer
 * @alias getExamLayer
 * @see https://github.com/axios/axios
 */

export interface TGetExamLayerParams {
  id: string;
  scan_id: string;
}

export async function getExamLayer(
  this: BaseRESTClient,
  params: TGetExamLayerParams,
  cancelToken?: CancelToken
) {
  try {
    return await this.client.get<TResponse>(
      `/examinations/${params.id}/scans/${params.scan_id}/layers`,
      {
        cancelToken,
      }
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
