import { patientConstants } from 'redux/constants/patient';
import { IPatientState, PatientsTypes } from 'types/patients';

const initialState: IPatientState = {
  requesting: false,
  errors: null,
  data: {
    examinations_left: 0,
    patients: [],
  },

  examinations: [],
};

const patientReducer = (state = initialState, action: PatientsTypes) => {
  switch (action.type) {
    //CREATE PATIENT
    case patientConstants.CREATE_PATIENT_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case patientConstants.CREATE_PATIENT_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };
    case patientConstants.CREATE_PATIENT_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    // GET PATIENTS
    case patientConstants.GET_PATIENT_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case patientConstants.GET_PATIENT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        requesting: false,
        errors: null,
      };
    case patientConstants.GET_PATIENT_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    //DELETE PATIENT
    case patientConstants.DELETE_PATIENT_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case patientConstants.DELETE_PATIENT_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };
    case patientConstants.DELETE_PATIENT_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    //UPDATE PATIENT
    case patientConstants.UPDATE_PATIENT_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case patientConstants.UPDATE_PATIENT_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };
    case patientConstants.UPDATE_PATIENT_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    // GET PATIENT EXAMINATIONS
    case patientConstants.GET_PATIENT_EXAMINATIONS_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case patientConstants.GET_PATIENT_EXAMINATIONS_SUCCESS:
      return {
        ...state,
        requesting: false,
        examinations: action.payload,
      };
    case patientConstants.GET_PATIENT_EXAMINATIONS_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    // FILTER_PATIENT
    case patientConstants.FILTER_PATIENT_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };
    case patientConstants.FILTER_PATIENT_SUCCESS:
      return {
        ...state,
        requesting: false,
        data: action.payload,
      };
    case patientConstants.FILTER_PATIENT_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default patientReducer;
