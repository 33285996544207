import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

interface TUpdateProgressionSettingsPayload {
  segmentation_classes: Record<string, boolean>;
}

export async function updateProgressionSettings(
  this: BaseRESTClient,
  payload: TUpdateProgressionSettingsPayload
) {
  try {
    return await this.client.post<TResponse>(
      '/user/progression-settings',
      payload
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
}
