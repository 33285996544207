import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse, TResponseError } from '../../types';
/**
 * @function resetConfirmation
 * @alias resetConfirmation
 * @see https://github.com/axios/axios
 */

type TResetConfirmationPayload = {
  token: string;
  newPassword: string;
};

export async function resetConfirmation(
  this: BaseRESTClient,
  payload: TResetConfirmationPayload
) {
  try {
    return await this.client.post<TResponse<any>>(
      `/auth/reset-confirmation`,
      payload
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
