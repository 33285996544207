import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

import { IPatient } from 'types/patients';

interface TGetSinglePatientParams {
  id: string;
}

export async function getSinglePatient(
  this: BaseRESTClient,
  params: TGetSinglePatientParams
) {
  try {
    return await this.client.get<TResponse<IPatient>>(`/patients/${params.id}`);
  } catch (err) {
    console.error(err);
    throw err;
  }
}
