import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

// Represents the payload for the 'demoSendVerificationCode' function.
export type TDemoVerifyCodePayload = {
  email: string;
  code: string;
};

/**
 * This function sends a verification code through the demo endpoint.
 * @param payload - contains the email and the verification code.
 * @returns The server response as a promise.
 * @throws Will throw an error if the request fails.
 */
export async function demoSendVerificationCode(
  this: BaseRESTClient,
  payload: TDemoVerifyCodePayload
) {
  try {
    return await this.client.post<TResponse>(
      '/demo/verification-code',
      payload
    );
  } catch (err) {
    console.error(err);
    throw err;
  }
}
