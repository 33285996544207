import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

export async function updateUserInfo(this: BaseRESTClient, data: FormData) {
  try {
    return await this.client.post<TResponse>('/user/update-info', data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
