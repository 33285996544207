import BaseRESTClient from '../../../BaseRESTClient';
import { TAltrisJWT, TResponseError, TResponseSuccess } from '../../types';

/**
 * @function login
 * @alias login
 * @see https://github.com/axios/axios
 */

export async function login(this: BaseRESTClient, payload: TSignInPayload) {
  try {
    return await this.client.post<TResponseSuccess<TAltrisJWT>>(
      `/auth/sign-in`,
      payload
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}

interface TSignInPayload {
  email: string;
  password: string;
}
