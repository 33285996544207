import React, { useCallback, useRef } from 'react';

import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';

import Backdrop from './components/Backdrop';
import { ModalUIContext } from './ModalUIContext';
import { TModalProps } from './types';

import s from './Modal.module.scss';

/**
 *  Modal
 *  @param className
 *  Created by westprophet on 02.07.2024
 * @param open
 * @param children
 * @param backdrop
 * @param onClose
 * @param onBackdropClick
 * @param shouldCloseOnOverlayClick
 */

export default function Modal({
  className = '',
  open,
  children,
  backdrop = true,
  onClose,
  onBackdropClick,
  shouldCloseOnOverlayClick = false,
}: TModalProps) {
  const nodeRef = useRef(null);

  const onCloseHandler = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);
  return (
    <ModalUIContext.Provider
      value={{
        onClose: onCloseHandler,
        open,
        shouldCloseOnOverlayClick,
        backdrop,
      }}
    >
      <CSSTransition
        in={open}
        timeout={300}
        nodeRef={nodeRef}
        classNames={{
          enter: s.enter,
          enterActive: s.enterActive,
          exit: s.exit,
          exitActive: s.exitActive,
        }}
        unmountOnExit
      >
        <div ref={nodeRef} className={cn(s.Modal, className)}>
          {children}
        </div>
      </CSSTransition>
      {backdrop && (
        <Backdrop
          open={open}
          onClick={onBackdropClick ?? onCloseHandler}
          disabled={shouldCloseOnOverlayClick}
        />
      )}
    </ModalUIContext.Provider>
  );
}
