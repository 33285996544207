import BaseRESTClient from '../../../BaseRESTClient';
import { TResponseError } from '../../types';

type TSignInPayload = {
  token: string;
};

/**
 * @function loginUserByGoogle
 * @alias loginUserByGoogle
 * @see https://github.com/axios/axios
 */

export async function loginUserByGoogle(
  this: BaseRESTClient,
  payload: TSignInPayload
) {
  try {
    return await this.client.post(`/auth/google/callback`, payload);
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
