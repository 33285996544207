import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse, TResponseError } from '../../types';

/**
 * @function getLayerETDRS
 * @alias getLayerETDRS
 * @see https://github.com/axios/axios
 */

interface TGetLayerETDRSParams {
  exam_id: string;
  layers_slug: string;
}
export async function getLayerETDRS(
  this: BaseRESTClient,
  params: TGetLayerETDRSParams,
  cancelToken?: CancelToken
) {
  try {
    return await this.client.get<TResponse>(
      `/examinations/${params.exam_id}/etdrs/layers/${params.layers_slug}`,
      { cancelToken }
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
