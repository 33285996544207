import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

interface ISendSurveyPayload {
  question: string;
  answers: { field: string; value: string }[] | any[];
}

/**
 * Sends a survey.
 * @param {IUnsubscribeSurveyData} data - The survey data.
 * @return {Promise<TResponse>} The result of sending the survey.
 * @throws Will throw an error if the request fails.
 */
export async function sendSurvey(
  this: BaseRESTClient,
  payload: ISendSurveyPayload
) {
  try {
    return await this.client.post<TResponse>('/survey/unsubscribe', payload);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
