import { t } from 'i18next';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { altrisRESTClient } from '../../../_api';

import { getInvitedUserData, updateInvitedUserData } from 'api/auth';
import {
  registerInvitedTeamMemberError,
  registerInvitedTeamMemberSuccess,
  updateTeamMemberSuccess,
} from 'redux/actions/userAuth';
import { userConstants } from 'redux/constants/userAuth';
import { userAuthenticatedConstants } from 'redux/constants/userAuthenticated';
import notify from 'utils/toast';

function* userRegisterByInviteWatcher() {
  yield takeEvery(
    userConstants.REGISTER_INVITED_TEAM_MEMBER_REQUEST,
    userRegistrationByInviteWorker
  );
}

function* userRegistrationByInviteWorker({ payload, action }) {
  try {
    const { data: invitedUserData } = yield getInvitedUserData(payload);

    if (invitedUserData.status === 'Error') {
      throw new Error(invitedUserData.message);
    }

    yield action.setUserInfo({
      ...action.userInfo,
      id: invitedUserData.data.id,
      update_token: invitedUserData.data.update_token,
    });

    yield put(registerInvitedTeamMemberSuccess());
  } catch (error) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(registerInvitedTeamMemberError('error'));
    yield notify('error', errorMessage);
  }
}

function* userRegisterByInviteTwoWatcher() {
  yield takeEvery(
    userConstants.UPDATE_INVITED_TEAM_MEMBER_REQUEST,
    userRegistrationByInviteTwoWorker
  );
}

function* userRegistrationByInviteTwoWorker({ payload, action }) {
  try {
    const { data: userData } = yield updateInvitedUserData(payload.id, {
      country_id: payload.data.country_id,
      password: payload.data.password,
      profession_id: payload.data.profession_id,
      update_token: payload.data.update_token,
    });

    if (userData.status === 'Error') {
      throw new Error(userData.message);
    }

    altrisRESTClient.localAuthManager.login(userData.data.token);
    yield call(
      [localStorage, localStorage.setItem],
      'token',
      userData.data.token
    );
    yield call(
      [localStorage, localStorage.setItem],
      'method',
      userAuthenticatedConstants.LOGGED_BY_MAIL
    );

    yield action.setCurrentStep(action.currentStep + 1);
    yield put(updateTeamMemberSuccess());
  } catch (error) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    console.error('error ', error);
    yield notify('error', errorMessage);
  }
}

export default function* authByInviteSaga() {
  yield fork(userRegisterByInviteWatcher);
  yield fork(userRegisterByInviteTwoWatcher);
}
