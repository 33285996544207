import { AxiosRequestConfig } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponseError, TResponseSuccess } from '../../types';

import { TExamination } from 'types/examination';

/**
 * @function getExamination
 * @alias getExamination
 * @see https://github.com/axios/axios
 */

export interface TGetExaminationParams {
  examId: string;
}
export type TGetExaminationError = TResponseError;
export type TGetExaminationSuccess = TExamination;

export async function getExamination(
  this: BaseRESTClient,
  params: TGetExaminationParams,
  config?: AxiosRequestConfig
): Promise<TGetExaminationSuccess> {
  try {
    const { data } = await this.client.get<
      TResponseSuccess<TGetExaminationSuccess>
    >(`/examination/${params.examId}`, {
      ...config,
    });
    return data.data;
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
