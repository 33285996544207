import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

import { TSettingsSaveDataPayload } from './saveSettings';

import { ISettingsData } from 'types/settings';

/**
 * Fetches the subscription plans.
 * @return {Promise<TResponse>} The subscription plans.
 * @throws Will throw an error if the request fails.
 */
export async function getSettings(this: BaseRESTClient) {
  try {
    return await this.client.get<
      TResponse<{
        [key: string]: ISettingsData['status'];
      }>
    >('/user/platform-settings');
  } catch (error) {
    console.error(error);
    throw error;
  }
}
