import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

export async function updateReportSettings(
  this: BaseRESTClient,
  payload: FormData
) {
  try {
    return await this.client.post<TResponse>(
      '/user/update-report-settings',
      payload
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
}
