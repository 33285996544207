import BaseRESTClient from '../../../BaseRESTClient';
import AltrisRESTClient from '../../AltrisRESTClient';
import { TResponse } from '../../types';

interface TDeleteTeamMemberParams {
  id: number;
}
/**
 * Deletes a team member.
 * @param {number} id - The id of the team member to delete.
 * @return {Promise<TResponse>} The result of the deletion.
 * @throws Will throw an error if the request fails.
 */
export async function deleteTeamMember(
  this: BaseRESTClient,
  params: TDeleteTeamMemberParams
) {
  try {
    return await this.client.delete<TResponse>(
      `/team-member/delete/${params.id}`
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
}
