import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

import { IPatientData } from 'types/patients';

export async function getAllPatients(this: BaseRESTClient) {
  try {
    return await this.client.get<TResponse<IPatientData>>(`/patients`);
  } catch (err) {
    console.error(err);
    throw err;
  }
}
