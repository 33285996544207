// eslint-disable-next-line import/named
import axios, { AxiosInstance } from 'axios';

/**
 * @class BaseRESTClient
 * @category API
 * @alias BaseRESTClient
 * @see https://github.com/axios/axios
 * @constructor
 * @classdesc
 * Базовый класс клиента REST подключений
 * Хранит инструменты для перехвата и обновления токена на лету
 */

export default class BaseRESTClient {
  protected client: AxiosInstance;
  protected BASE_URL: string;

  constructor(BASE_URL: string) {
    this.BASE_URL = BASE_URL;
    this.client = axios.create({
      baseURL: this.BASE_URL,
      headers: {
        'Content-Type': 'text/plain;charset=UTF-8',
      },
    });
  }
  /**
   * Устанавливаем заголовок
   * @param name {string} имя заголовка
   * @param value {string} значение которое устанавливаем
   * @memberof BaseRESTClient
   */
  static setHeader(name: string, value: string) {
    if (value) {
      // @ts-ignore
      this.client.defaults.headers[name] = value;
    }
  }

  /**
   * Удаляем заголовок
   * @param name {string} имя заголовка которых хотим удалить
   * @memberof BaseRESTClient
   */
  static removeHeader(name: string) {
    if (name) {
      // @ts-ignore
      delete this.client.defaults.headers[name];
    }
  }
}
