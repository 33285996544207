import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse, TResponseError } from '../../types';

import { EtdrsMainData, IEtdrsData } from 'types/examination';

/**
 * @function getGccETDRS
 * @alias getGccETDRS
 * @see https://github.com/axios/axios
 */

interface TGetGccETDRSParams {
  exam_id: string;
  layers_slug: string;
}

export async function getGccETDRS(
  this: BaseRESTClient,
  params: TGetGccETDRSParams,
  cancelToken?: CancelToken
) {
  try {
    return await this.client.get<TResponse>(
      `/examinations/${params.exam_id}/etdrs/gcc/${params.layers_slug}`,
      {
        cancelToken,
      }
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
