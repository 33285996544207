import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

// Represents the payload for the 'demoReSendEmail' function.
export type TDemoEmailPayload = {
  email: string;
};

/**
 * This function resends an email through the demo endpoint.
 * @param payload - contains the email to which the request will be sent.
 * @returns The server response as a promise.
 * @throws Will throw an error if the request fails.
 */
export async function demoReSendEmail(
  this: BaseRESTClient,
  payload: TDemoEmailPayload
) {
  try {
    return await this.client.post<TResponse>('/demo/re-send-code', payload);
  } catch (err) {
    console.error(err);
    throw err;
  }
}
