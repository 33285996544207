import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse, TResponseError } from '../../types';

/**
 * @function getProgressionState
 * @alias getProgressionState
 * @see https://github.com/axios/axios
 */

interface TGetProgressionStateParams {
  exam_id: string;
}
export async function getProgressionState(
  this: BaseRESTClient,
  params: TGetProgressionStateParams,
  cancelToken?: CancelToken
) {
  try {
    return await this.client.get<TResponse>(
      `/examinations/${params.exam_id}/progression/state`,
      {
        cancelToken,
      }
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
