import React, { useCallback, useRef } from 'react';

import cn from 'classnames';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import s from './Backdrop.module.scss';

interface Props {
  className?: string;
  open: boolean;
  disabled?: boolean;
  onClick?(): void;
}

/**
 *  Created on 03.07.2024
 *  Backdrop component
 *  @param className
 * @param open
 * @param onClick
 * @param timeout
 */

export default function Backdrop({
  className = '',
  open,
  onClick,
  disabled = false,
}: Props) {
  const ref = useRef(null);

  const onClickHandler = useCallback(() => {
    if (onClick && !disabled) {
      onClick();
    }
  }, [onClick, disabled]);

  return createPortal(
    <CSSTransition
      in={open}
      timeout={300}
      nodeRef={ref}
      classNames={{
        enter: s.enter,
        enterActive: s.enterActive,
        exit: s.exit,
        exitActive: s.exitActive,
      }}
      unmountOnExit
    >
      <div
        ref={ref}
        className={cn(s.Backdrop, className)}
        onClick={onClickHandler}
      />
    </CSSTransition>,
    document.body
  );
}
