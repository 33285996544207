import { ChangeEvent, lazy, memo, Suspense, useEffect, useRef } from 'react';

import { useGoogleLogin } from '@react-oauth/google';
import cn from 'classnames';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ReactComponent as GoogleIcon } from 'assets/icons/google.svg';
import { ReactComponent as Envelope } from 'assets/icons/icon-envelope.svg';
import { ReactComponent as Lock } from 'assets/icons/lock.svg';
import Button from 'components/Button';
import FormField from 'components/FormComponents';
import Text from 'components/Text';
import {
  userAuthByGoogleError,
  userAuthByGoogleRequest,
  userLoginRequest,
} from 'redux/actions/userAuth';
import { ROUTES } from 'utils/constants';
import { ga } from 'utils/helpers';
import { validateSignIn } from 'validation';

import styles from './SignIn.module.scss';

const ReCAPTCHA = lazy(() => import('react-google-recaptcha'));

interface TSingInFormValues {
  email: string;
  password: string;
}

const SignIn = memo(() => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(undefined, {
    keyPrefix: 'sign_in_page',
  });
  const recaptchaRef = useRef<any>(null);

  useEffect(() => {
    ga('open_page_signIn');
  }, []);

  const googleLoginHandler = useGoogleLogin({
    onSuccess: ({ access_token }) => {
      dispatch(userAuthByGoogleRequest(access_token));
    },
    onError: (response) => {
      console.error('Error', response.error);
      dispatch(userAuthByGoogleError(response.error));
    },
    flow: 'implicit',
  });

  // FormikConfig<Values>.initialValues:
  const onSubmitWithReCAPTCHA = async (
    value: TSingInFormValues,
    actions: FormikHelpers<TSingInFormValues>
  ) => {
    if (recaptchaRef.current) {
      await recaptchaRef.current.executeAsync();
      dispatch(
        userLoginRequest(value, {
          success() {
            ga('Login_1');
          },
          error(error: { message: string; errors: { [k: string]: string } }) {
            // we show the error only in this field and only the abstract value for the security case
            actions.setErrors({
              email: error.message,
              password: error.message,
            });
            // or use snackbar
          },
        })
      );
      recaptchaRef.current.reset();
    }
  };

  return (
    <>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={() => validateSignIn(i18n.t)}
        onSubmit={onSubmitWithReCAPTCHA}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FormField
              type={'text'}
              component={'text'}
              name={'email'}
              id={'email'}
              value={values.email}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;

                if (value.length > 50) return;

                setFieldValue('email', value);
              }}
              label={t('email_label')}
              icon={<Envelope />}
              errorType={'simple'}
              className={styles.email}
            />
            <FormField
              type={'password'}
              component={'password'}
              name={'password'}
              id={'password'}
              value={values.password}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;

                if (value.length > 50) return;

                setFieldValue('password', value);
              }}
              label={t('password_label')}
              icon={<Lock />}
              errorType={'simple'}
              className={styles.password}
            />
            <div className={styles.row}>
              {/* <span>{t('Remember me')}</span> */}
              <Button
                to={ROUTES.AUTH.FORGOT}
                appearance='simple'
                className={styles.forgot}
              >
                {t('forgot_password')}
              </Button>
            </div>
            <Button
              type={'submit'}
              className={cn(styles.signin, styles.button)}
            >
              {t('sign_in')}
            </Button>
          </Form>
        )}
      </Formik>
      <Suspense fallback={''}>
        <ReCAPTCHA
          theme='dark'
          ref={recaptchaRef}
          size='invisible'
          sitekey={`${process.env.REACT_APP_CAPCHA_SITE_KEY}`}
        />
      </Suspense>
      <Text color='light' className={styles.or}>
        {t('or')}
      </Text>
      <Button
        className={styles.google}
        appearance='simple'
        onClick={() => googleLoginHandler()}
      >
        <GoogleIcon /> {t('sign_in_with_google')}
      </Button>
    </>
  );
});

SignIn.displayName = 'SignIn';

export default SignIn;
