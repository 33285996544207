import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponseError } from '../../types';

/**
 * @function getProgressCommonPathologiesData
 * @alias getProgressCommonPathologiesData
 * @see https://github.com/axios/axios
 */

interface Params {
  examination_ids: string[];
}

export async function getProgressCommonPathologiesData(
  this: BaseRESTClient,
  params: Params,
  cancelToken?: CancelToken
) {
  try {
    return await this.client.get<Response>(
      `/examinations/progress/common/pathologies`,
      {
        params: { ...params },
        cancelToken,
      }
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
