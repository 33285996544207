import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

export async function getSubscription(this: BaseRESTClient) {
  try {
    return await this.client.get<TResponse>('/subscriptions/plans');
  } catch (error) {
    console.error(error);
    throw error;
  }
}
