import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

/**
 * Creates a user backup.
 * @return {Promise<TResponse>} The result of the user backup creation.
 * @throws Will throw an error if the request fails.
 */
export async function createUserBackup(this: BaseRESTClient) {
  try {
    return await this.client.post<TResponse>('/examination/download');
  } catch (error) {
    console.error(error);
    throw error;
  }
}
