import BaseRESTClient from '../../../BaseRESTClient';
import { TOCTProducer, TResponse, TResponseError } from '../../types';

/**
 * @function getOctProducers
 * @alias getOctProducers
 * @see https://github.com/axios/axios
 */

export async function getOctProducers(this: BaseRESTClient) {
  try {
    return await this.client.get<TResponse<TOCTProducer[]>>(
      `/get-oct-producers`
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
