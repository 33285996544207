import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

/**
 * Subscribes to a plan.
 * @param {FormData} data - The subscription data.
 * @return {Promise<TResponse>} The result of subscription.
 * @throws Will throw an error if the request fails.
 */
export async function setSubscribePlan(
  this: BaseRESTClient,
  payload: FormData
) {
  try {
    return await this.client.post<TResponse>(
      '/subscriptions/subscribe',
      payload
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
}
