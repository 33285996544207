import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

export async function getProgressionSettings(
  this: BaseRESTClient,
  cancelToken?: CancelToken
) {
  try {
    return await this.client.get<TResponse>('/user/progression-settings', {
      cancelToken,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}
