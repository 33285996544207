import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

/**
 * Deletes a user backup.
 * @return {Promise<TResponse>} The result of the user backup deletion.
 * @throws Will throw an error if the request fails.
 */
export async function deleteUserBackup(this: BaseRESTClient) {
  try {
    return await this.client.delete<TResponse>('/examination/download');
  } catch (error) {
    console.error(error);
    throw error;
  }
}
