import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse, TResponseError } from '../../types';

/**
 * @function getExaminationStatus
 * @alias getExaminationStatus
 * @see https://github.com/axios/axios
 */

export async function getExaminationStatus(
  this: BaseRESTClient,
  payload: { id: string }
) {
  try {
    return await this.client.get<TResponse>(
      `/examinations/${payload.id}/status`
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
