import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

export type TContactUsPayload = FormData;

/**
 * Sends a contact us form
 * @param {TContactUsPayload} payload - The form data to be sent.
 * @returns {Promise<TResponse>} The server's response.
 * @throws Will throw an error if the request fails.
 */
export async function sendContactUsForm(
  this: BaseRESTClient,
  payload: TContactUsPayload
) {
  try {
    return await this.client.post<TResponse>('/user/contact-us', payload);
  } catch (err) {
    console.error(err);
    throw err;
  }
}
