import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

export interface TVerifyUserPayload {
  password: string;
  key: string;
}
/**
 * Verifies a user.
 * @param {IVerifyUser} data - The verification data.
 * @return {Promise<TResponse>} The result of the verification.
 * @throws Will throw an error if the request fails.
 */
export async function verifyUser(
  this: BaseRESTClient,
  payload: TVerifyUserPayload
) {
  try {
    return await this.client.post<TResponse>('/verify', payload);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
