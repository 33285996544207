import BaseRESTClient from '../../../BaseRESTClient';
import { TProfession, TResponse, TResponseError } from '../../types';

/**
 * @function getProfessions
 * @alias getProfessions
 * @see https://github.com/axios/axios
 */

export async function getProfessions(this: BaseRESTClient) {
  try {
    return await this.client.get<TResponse<TProfession[]>>(`/professions`);
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
