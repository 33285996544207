import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

import { IChangePassword } from 'types/editProfile';

export async function updateUserPassword(
  this: BaseRESTClient,
  data: IChangePassword
) {
  try {
    return await this.client.put<TResponse>('/user/update-password', data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
