import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse, TResponseError } from '../../types';

interface TProgressPathologyScansDataParams {
  scan_ids: string[];
  pathology_id: string;
}

/**
 * @function getProgressPathologyScansData
 * @alias getProgressPathologyScansData
 * @see https://github.com/axios/axios
 */

export async function getProgressPathologyScansData(
  this: BaseRESTClient,
  params: TProgressPathologyScansDataParams,
  cancelToken?: CancelToken
) {
  try {
    return await this.client.get<TResponse>(
      `/examinations/progress/pathology/scans`,
      {
        params,
        cancelToken,
      }
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
