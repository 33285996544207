import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

interface TGetPurchaseTypeBySlugParams {
  slug: string;
}
/**
 * Get purchase type by its slug
 * @returns {Promise<TResponse>} The server's response.
 * @throws Will throw an error if the request fails.
 * @param params
 */
export async function getPurchaseTypeBySlug(
  this: BaseRESTClient,
  params: TGetPurchaseTypeBySlugParams
) {
  try {
    return await this.client.get<TResponse>(`/purchase-types/${params.slug}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
