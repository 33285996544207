import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse, TResponseError } from '../../types';

/**
 * @function getInvitedUserData
 * @alias getInvitedUserData
 * @see https://github.com/axios/axios
 */

interface TGetInvitedUserDataRes {
  id: string;
  update_token: string;
}
interface TGetInvitedUserDataParams {
  invited_token: string;
}

export async function getInvitedUserData(
  this: BaseRESTClient,
  params: TGetInvitedUserDataParams,
  cancelToken?: CancelToken
) {
  try {
    return await this.client.get<TResponse<TGetInvitedUserDataRes>>(
      `/auth/invited-user-data/${params.invited_token}`,
      { cancelToken }
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
