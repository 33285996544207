import AltrisRESTClient from '../../AltrisRESTClient';
import { TResponse } from '../../types';

/**
 * Fetches all team members.
 * @return {Promise<TResponse>} A list of all team members.
 * @throws Will throw an error if the request fails.
 */
export async function getAllTeamMembers(this: AltrisRESTClient) {
  try {
    return await this.client.get<TResponse>('/team-member/all');
  } catch (error) {
    console.error(error);
    throw error;
  }
}
