import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

export type TDemoEmailPayload = {
  email: string;
};

/**
 * This function sends an email through the demo endpoint.
 * @param {TDemoEmailPayload} payload The data to send to the endpoint.
 * @returns {Promise<TResponse>} The result from the HTTP request.
 * @throws Will throw an error in case of HTTP errors.
 */
export async function demoSendEmail(
  this: BaseRESTClient,
  payload: TDemoEmailPayload
) {
  try {
    return await this.client.post<TResponse>('/demo/send-email', payload);
  } catch (err) {
    console.error(err);
    throw err;
  }
}
