import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { updateExamination } from 'api/examination';
import {
  updateExaminationError,
  updateExaminationSuccess,
} from 'redux/actions/examination';
import { closeModal } from 'redux/actions/modal';
import { getPatientRequest } from 'redux/actions/patient';
import { ExamActionTypes } from 'redux/constants/examination';
import { IWorkerProps } from 'redux/types/examination';
import { UpdateExaminationData } from 'types/examination';
import notify from 'utils/toast';

function* updateExaminationWatcher() {
  yield takeEvery(
    ExamActionTypes.UPDATE_EXAMINATION_REQUEST,
    updateExaminationWorker
  );
}

interface IUpdateExamWorker extends IWorkerProps {
  type: ExamActionTypes;
  payload: UpdateExaminationData;
}

function* updateExaminationWorker({ payload, action }: IUpdateExamWorker) {
  try {
    const { exam_id, data } = payload;

    const { data: updatePatient } = yield updateExamination(exam_id, data);

    yield put(getPatientRequest());
    yield put(updateExaminationSuccess());
    if (updatePatient.status === 'Error') {
      throw new Error(updatePatient.message);
    }
    // disable one notify for expamination page
    if (!(window.location.pathname.indexOf('examination') > 0)) {
      yield notify(
        'success',
        t('notifications.examination_updated_successfully')
      );
    }

    yield put(closeModal());
    action?.success?.();
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');

    console.error(error);
    yield put(updateExaminationError('Something go wrong'));
    yield notify('error', errorMessage);
    yield put(closeModal());
  }
}

export default function* updateExaminationSaga() {
  yield fork(updateExaminationWatcher);
}
