import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

export async function getUserInfo(this: BaseRESTClient) {
  try {
    return await this.client.get<TResponse>('/user/info');
  } catch (error) {
    console.error(error);
    throw error;
  }
}
