import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

import { CREATE_EXAMINATION_FLOW } from 'utils/constants';

interface TCreatePatientPayload {
  birth_date: string;
  description: string;
  flow?: CREATE_EXAMINATION_FLOW;
  gender: string;
  name: string;
  patient_from_dicom?: boolean;
  pid: string;
  surname: string;
}

export async function createPatient(
  this: BaseRESTClient,
  payload: TCreatePatientPayload
) {
  try {
    return await this.client.post<TResponse>(`/patients`, payload);
  } catch (err) {
    console.error(err);
    throw err;
  }
}
