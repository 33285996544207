import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse, TResponseError } from '../../types';

/**
 * @function getSegmentationScans
 * @alias getSegmentationScans
 * @see https://github.com/axios/axios
 */
interface GetSegmentationScans {
  id: string;
  scans_ids: string[];
}
export async function getSegmentationScans(
  this: BaseRESTClient,
  params: GetSegmentationScans,
  cancelToken?: CancelToken
) {
  try {
    return await this.client.post<TResponse>(
      `/examinations/${params.id}/scans`,
      { scans_ids: params.scans_ids },
      { cancelToken }
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
