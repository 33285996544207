import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

/**
 * Returns all purchase types
 * @returns {Promise<TResponse>} The server's response.
 * @throws Will throw an error if the request fails.
 */
export async function getAllPurchaseType(this: BaseRESTClient) {
  try {
    return this.client.get<TResponse>('/purchase-types');
  } catch (error) {
    console.error(error);
    throw error;
  }
}
