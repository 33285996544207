import AltrisRESTClient from '../../AltrisRESTClient';
import { TResponse } from '../../types';

/**
 * Unsubscribes a plan.
 * @return {Promise<TResponse>} The result of unsubscription.
 * @throws Will throw an error if the request fails.
 */
export async function getUnsubscribePlan(this: AltrisRESTClient) {
  try {
    return await this.client.delete<TResponse>('/subscriptions');
  } catch (error) {
    console.error(error);
    throw error;
  }
}
