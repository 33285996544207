import AltrisRESTClient from '../../AltrisRESTClient';
import { TResponse } from '../../types';

/**
 * Fetches the payment statistics.
 * @return {Promise<TResponse>} The payment statistics.
 * @throws Will throw an error if the request fails.
 */
export async function getPaymentStatistics(this: AltrisRESTClient) {
  try {
    return await this.client.get('/user/payment-statistics');
  } catch (error) {
    console.error(error);
    throw error;
  }
}
