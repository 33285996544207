import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse, TResponseError } from '../../types';

import { IMeasurementsPolygonScan } from 'types/examination';

export interface TUpdateMeasDataPayload {
  measurements: IMeasurementsPolygonScan[];
}
export interface TUpdateMeasDataParams {
  exam_id: string;
  scan_id: string;
}

/**
 * @function updateMeasurementsScan
 * @alias updateMeasurementsScan
 * @see https://github.com/axios/axios
 */

export async function updateMeasurementsScan(
  this: BaseRESTClient,
  params: TUpdateMeasDataParams,
  payload: TUpdateMeasDataPayload,
  cancelToken?: CancelToken
) {
  try {
    return await this.client.put<TResponse>(
      `/examinations/${params.exam_id}/scans/${params.scan_id}/measurements`,
      payload,
      {
        cancelToken,
      }
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
