import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponseError } from '../../types';

interface TGetMeasurementsScanParams {
  exam_id: string;
  scan_id: string;
}

/**
 * @function getMeasurementsScan
 * @alias getMeasurementsScan
 * @see https://github.com/axios/axios
 */

export async function getMeasurementsScan(
  this: BaseRESTClient,
  params: TGetMeasurementsScanParams,
  cancelToken?: CancelToken
) {
  try {
    return await this.client.get(
      `/examinations/${params.exam_id}/scans/${params.scan_id}/measurements`,
      { cancelToken }
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
