import { CancelToken } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse, TResponseError } from '../../types';

import { ICreateExamination } from 'types/examination';

/**
 * @function createExamination
 * @alias createExamination
 * @see https://github.com/axios/axios
 */

export async function createExamination(
  this: BaseRESTClient,
  payload: ICreateExamination,
  cancelToken?: CancelToken
) {
  try {
    return await this.client.post<TResponse>(`/examinations`, payload, {
      cancelToken,
    });
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
