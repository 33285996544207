import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse } from '../../types';

interface TGetCodeUpdateUserPasswordPayload {
  old_password: string;
  new_password: string;
}

export async function getCodeUpdateUserPassword(
  this: BaseRESTClient,
  payload: TGetCodeUpdateUserPasswordPayload
) {
  try {
    return await this.client.post<TResponse>(
      '/user/update-password/code',
      payload
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
}
