import BaseRESTClient from '../../../BaseRESTClient';
import { TResponse, TResponseError } from '../../types';

/**
 * @function getPaymentIntent
 * @alias getPaymentIntent
 * @see https://github.com/axios/axios
 */

interface TGetPaymentIntentData {
  data: any;
}

export async function getPaymentIntent(
  this: BaseRESTClient,
  data: TGetPaymentIntentData
) {
  try {
    return await this.client.get<TResponse<TGetPaymentIntentData>>(
      `/subscriptions/payment-methods`,
      data
    );
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
